<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-transparent">
    <div class="container-fluid">
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-center" id="navbarNav">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link" href="#">01  :  Home</a>
            <span class="separator">|</span>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">02  :  Works</a>
            <span class="separator">|</span>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">03  :  About</a>
            <span class="separator">|</span>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#">04  :  Contact</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {};
</script>

<style scoped>
/* Add your specific styles for the transparent background and hover effects */
.navbar {
  background-color: rgba(0, 0, 0, 0.5); /* Transparent background */
}

.navbar-nav {
  font-family: Menlo, Monaco, Consolas, Courier New, monospace;
  letter-spacing: 2px;
  display: flex;
  list-style: none;
  padding: 0;
  flex-wrap: wrap;
}

.nav-item {
  margin-right: 10px;
}

.nav-link {
  text-decoration: none;
  color: #fff;
  transition: color 0.3s ease;
  position: relative;
  margin-right: 40px;
}

.nav-link:hover {
  color: #ccff99; /* Change color on hover */
}

.separator {
  color: #fff;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: 50px; /* Adjust as needed for horizontal spacing */
}

/* Responsive Styles */
@media (max-width: 991px) {
  .navbar-nav {
    margin-top: -38px;
    justify-content: center; /* Center items on small screens */
  }

  .nav-link {
    margin-right: 20px; /* Reduce margin for small screens */
    margin-bottom: 10px; /* Add space between items on small screens */
  }

  .separator {
    display: none; /* Hide separator on small screens */
  }
}
</style>