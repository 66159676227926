<template>
  <div class="parallax-container" ref="parallaxContainer">
    <div class="background" ref="background"></div>
    <HeaderComponent />
    <div class="content">
      <h1>Hello,</h1>
    </div>
    <div class="content">
      <!-- Text content goes here -->
      <h1><br />
      <span id="typedString"></span>
      </h1>
    </div>
  </div>
</template>

<script>
import Typed from 'typed.js';
import HeaderComponent from './HeaderComponent.vue';

export default {
  name: 'LandingPage',
  components: {HeaderComponent},
  props: {},
  mounted() {
    this.initTyped();
    this.parallaxBackground();
  },
  methods: {
    initTyped() {
      new Typed('#typedString', {
        strings: ['I am Tejaswi Singh', 'Working as a Full Stack Software Engineer'],
        typeSpeed: 100,
        showCursor: true,
        loop: true,
      });
    },
    parallaxBackground() {
      const parallaxContainer = this.$refs.parallaxContainer;
      const background = this.$refs.background;
      parallaxContainer.addEventListener('mousemove', (e) => {
        const x = e.clientX / window.innerWidth - 0.5;
        const y = e.clientY / window.innerHeight - 0.5;
        background.style.transform = `translate(${x * 25}px, ${y * 25}px)`;
      });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  font-weight: 900;
  font-size: xx-large;
}
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.parallax-container {
  height: 100vh;
  overflow: hidden;
  position: relative;
  background-color: black;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('~@/../assets/images/space.jpg');
  background-size: cover;
  background-position: center;
  will-change: transform;
  transform: translateZ(0);
  transition: transform 0.3s ease-out;
  background-color: black;
}

.content {
  font-family: Menlo, Monaco, Consolas, Courier New, monospace;
  letter-spacing: 2px;
  position: absolute;
  top: 15%;
  left: 7%;
  color: #ccff99;
}

/* Media Query for Smaller Screens */
@media (max-width: 991px) {
  .content {
    top: 35%; /* Adjust the value as needed */
    color: #ccff99;
  }
}
</style>
